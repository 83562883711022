import React, { useContext } from 'react'
import { BLACKOUT_TYPE } from '../../constants/blackout-type'
import { StatefulTooltip } from 'baseui/tooltip'
import TimeFormatter from 'components/utils/time-formatter'
import EventIcon from 'components/ui/specific/EventIcon'
import Tag from 'components/ui/specific/EventTag'
import {
  Door,
  Prohibit,
  ArrowLineDown,
  ArrowLineUp,
  BatteryEmpty,
  BatteryFull,
  ClockCounterClockwise
} from '@phosphor-icons/react'
import {
  FIXED_UNASSIGNED_COLUMN,
  useDockAssigmentContext
} from '../../contexts/dock-assigment.context'
import EventTooltip from 'components/ui/specific/EventTooltip'
import { formatTitleForAppointment } from '../../utils/title-formatter'
import { CurrentUserContext } from '../../homepage/current-user-context'
import { Avatar } from 'baseui/avatar'
import { KIND } from 'baseui/tag'
import {
  AppointmentTypeName,
  ConfirmationIdContainer,
  DockOrSlotName,
  DriverAvatarOverrides,
  EventTileContainer,
  EventTileNoBlackoutContainer,
  EventTileStatefulTooltipOverrides,
  EventTileTitle,
  EventTileTop,
  EventTileWrapper,
  TaskTimeTagOverrides,
  TrailerIcon,
  YardAppointmentTime,
  YardJockeyAvatarOverrides
} from './EventTile.styled'
import { INBOUND_TYPE, OUTBOUND_TYPE } from '../../constants/appointment_types'
import { TRAILER_EMPTY_STATUS } from '../../models/Trailer'

const DEFAULT_DOCK_VALUE = 'Assign location'
const YARD_ICONS_SIZE = 16
const ICONS_SIZE = 10
export interface CalendarEventProps {
  event: {
    title: string
    start: string | Date
    end: string | Date
    allDay: boolean
    _def?: {
      resourceIds?: string[]
    }
    extendedProps: {
      subtitle?: string
      purchaseOrderIdentifiers?: string[]
      equipmentTypeId?: string
      recurringAppointmentBlueprintId?: string
      confirmationId?: string
      showAppointmentId?: boolean
      type?: string
      daysOfWeek?: boolean
      dockName?: string
      schedulerName?: string
      driverName?: string
      carrierName?: string
      status?: string
      appointmentTypeName?: string
      purchaseOrders?: string
      name?: string
      dockTime?: string
      carrierScac?: string
      trailerNumber?: string
      trailerState?: string
      trailerStatus?: string
      trailerOwner?: string
      trailerLocation?: string
      taskTime?: string | Date
      slotName?: string
      trailerYardJockey?: string
      movementStatus?: string
      yardScheduleView?: boolean
      identifiers?: Array<{
        prompt: string
        answer: string
      }>
    }
  }
}

const CalendarEvent = ({ event }: CalendarEventProps) => {
  const {
    start,
    allDay,
    title,
    extendedProps: {
      purchaseOrderIdentifiers,
      dockName,
      confirmationId,
      showAppointmentId,
      subtitle,
      appointmentTypeName,
      type,
      name,
      dockTime,
      trailerStatus,
      trailerNumber,
      driverName,
      taskTime,
      slotName,
      trailerYardJockey,
      yardScheduleView
    }
  } = event

  const { currentUser } = useContext(CurrentUserContext)
  const dockAssignmentContext = useDockAssigmentContext()
  const { dockOptions = [] } = dockAssignmentContext || {}
  const dockId = event._def?.resourceIds && event._def?.resourceIds[0]
  const dock = dockOptions.filter(dock => dock.id === dockId)[0]

  const renderEventContent = () => {
    return (
      <EventTileContainer yardScheduleView={yardScheduleView}>
        <EventTileWrapper data-testid={BLACKOUT_TYPE && `blackout-${name}`}>
          <EventTileTop>
            {type !== BLACKOUT_TYPE ? <EventIcon event={event} /> : <Prohibit />}
            {title ? (
              <EventTileTitle className="event-title" data-testid="event-title">
                {type === BLACKOUT_TYPE ? event.title : formatTitleForAppointment(title)}
              </EventTileTitle>
            ) : null}
            <span className="event-info">
              <AppointmentTypeName
                showIcon={
                  !(appointmentTypeName === INBOUND_TYPE || appointmentTypeName === OUTBOUND_TYPE)
                }>
                {appointmentTypeName === INBOUND_TYPE ? (
                  <ArrowLineDown size={ICONS_SIZE} />
                ) : appointmentTypeName === OUTBOUND_TYPE ? (
                  <ArrowLineUp size={ICONS_SIZE} />
                ) : (
                  appointmentTypeName
                )}
              </AppointmentTypeName>

              {confirmationId && showAppointmentId && (
                <ConfirmationIdContainer>{confirmationId}</ConfirmationIdContainer>
              )}

              {!yardScheduleView && (
                <span className="event-id">
                  {purchaseOrderIdentifiers?.filter(item => item).join('-')}
                </span>
              )}
            </span>
          </EventTileTop>

          {yardScheduleView && taskTime && (
            <Tag
              startEnhancer={() => <ClockCounterClockwise size={YARD_ICONS_SIZE} />}
              closeable={false}
              kind={KIND.accent}
              overrides={TaskTimeTagOverrides}>
              {new TimeFormatter('localCalendarTime12h').format(taskTime)}
            </Tag>
          )}

          {!yardScheduleView && (
            <span className="event-date">
              {!allDay && new TimeFormatter('localCalendarTime').format(start)}
            </span>
          )}
        </EventTileWrapper>

        {type !== BLACKOUT_TYPE ? (
          <EventTileNoBlackoutContainer>
            {yardScheduleView ? (
              <span className="event-trailer">
                {trailerStatus === TRAILER_EMPTY_STATUS ? (
                  <TrailerIcon>
                    <BatteryEmpty size={YARD_ICONS_SIZE} />
                  </TrailerIcon>
                ) : (
                  <TrailerIcon>
                    <BatteryFull size={YARD_ICONS_SIZE} />
                  </TrailerIcon>
                )}
                {trailerNumber}{' '}
                {driverName && (
                  <Avatar name={driverName} size="scale600" overrides={DriverAvatarOverrides} />
                )}
              </span>
            ) : (
              ''
            )}

            {type !== BLACKOUT_TYPE && subtitle && (
              <span className="event-subtitle">{subtitle}</span>
            )}

            {currentUser?.dockAssignmentActive && !yardScheduleView && (
              <span className="event-dock" data-testid="event-dock">
                <Door size={ICONS_SIZE} />
                {dockId === FIXED_UNASSIGNED_COLUMN.id
                  ? DEFAULT_DOCK_VALUE
                  : dock?.label || dockName || DEFAULT_DOCK_VALUE}
                {dockId === FIXED_UNASSIGNED_COLUMN.id
                  ? ''
                  : (dockTime as any) !== -1 &&
                    ` - ${new TimeFormatter('localCalendarTime').format(dockTime)}`}
              </span>
            )}

            {currentUser?.yardManagementActive && yardScheduleView && (
              <span className="event-yard" data-testid="event-yard">
                <Tag closeable={false} kind={KIND.accent}>
                  <DockOrSlotName>{slotName || dockName} </DockOrSlotName>{' '}
                  <YardAppointmentTime>
                    {new TimeFormatter('localCalendarTime12h').format(start)}
                  </YardAppointmentTime>
                </Tag>
                {trailerYardJockey && (
                  <Avatar
                    name={trailerYardJockey}
                    size="scale600"
                    overrides={YardJockeyAvatarOverrides}
                  />
                )}
              </span>
            )}
          </EventTileNoBlackoutContainer>
        ) : (
          ''
        )}
      </EventTileContainer>
    )
  }

  if (type === BLACKOUT_TYPE) return <>{renderEventContent()}</>

  return (
    <StatefulTooltip
      triggerType="hover"
      content={<EventTooltip event={event} />}
      placement="auto"
      overrides={EventTileStatefulTooltipOverrides}>
      {renderEventContent()}
    </StatefulTooltip>
  )
}

export default CalendarEvent
