import React, { useState, useEffect, useCallback } from 'react'
import { Block } from 'baseui/block'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { fancyToast } from 'components/utils'
import { facilityService } from 'components/services'
import Select from '../../ui/generic/Select'
import { INBOUND, OUTBOUND, useAppointmentContext } from 'components/contexts/appointment-context'
import { useFacilityContext } from 'components/contexts/facility-context'
import { useTranslation } from 'react-i18next'
import DatePicker from 'components/ui/generic/DatePicker'
import FormControl from 'components/ui/generic/FormControl'
import { DateTime as LuxonDateTime } from 'luxon'
import { LIVE } from '../../constants/handling_method'

export const CREATE = 'CREATE'

export const UPDATE = 'UPDATE'

type DateTimeProp = {
  type?: string
}

export function DateTime({ type }: DateTimeProp) {
  const { state, actions } = useAppointmentContext()
  const {
    appointments,
    handlingMethod,
    appointmentDirections,
    createAppointmentButtonDisableFlags: { isTimeSet }
  } = state
  const { t } = useTranslation()

  const { setAppointment, setIsTimeSet, getSchedulerIds } = actions
  const {
    state: { facility }
  } = useFacilityContext()

  const [availableDates, setAvailableDates] = useState([])
  const [availableTimes, setAvailableTimes] = useState([])
  const [handleTimeChangeHasBeenExecuted, setHandleTimeChangeHasBeenExecuted] = useState(false)

  const TIMEZONE = facility.timeZone || 'America/Chicago'

  const handleDateChange = date => {
    let newDate = LuxonDateTime.fromJSDate(date).setZone(TIMEZONE, { keepLocalTime: true })

    const arrivalTime = appointments[INBOUND].arrivalTime || appointments[OUTBOUND].arrivalTime

    if (arrivalTime && arrivalTime instanceof Date) {
      const currentDate = LuxonDateTime.fromJSDate(arrivalTime).setZone(TIMEZONE)

      newDate = newDate.set({
        hour: currentDate.hour,
        minute: currentDate.minute
      })
    } else if (arrivalTime) {
      const currentDate = LuxonDateTime.fromISO(arrivalTime).setZone(TIMEZONE)

      newDate = newDate.set({
        hour: currentDate.hour,
        minute: currentDate.minute
      })
    }

    appointmentDirections.forEach(direction => {
      setAppointment({ arrivalTime: newDate.toISO() }, direction)
    })
  }

  const handleTimeChange = time => {
    appointmentDirections.forEach(direction => {
      setAppointment({ arrivalTime: time }, direction)
    })
    setIsTimeSet(true)
    setHandleTimeChangeHasBeenExecuted(true)
  }

  const fetchAvailableDates = useCallback(async () => {
    if (handlingMethod && facility.id) {
      const activeDirections = appointmentDirections.filter(
        direction => appointments[direction].purchaseOrdersAttributes.length > 0
      )

      if (activeDirections.length) {
        const [{ fullTruckloadDays }, status] = await facilityService.availableDays(
          facility.id,
          getSchedulerIds(),
          appointments[activeDirections[0]].purchaseOrdersAttributes,
          handlingMethod
        )
        if (status !== 200) {
          console.error('Something went wrong with the available dates.')
          return
        } else {
          setAvailableDates(fullTruckloadDays)
        }
      }
    }
  }, [handlingMethod, facility.id, appointmentDirections, appointments])

  const fetchAvailableTimes = useCallback(
    async date => {
      const activeDirections = appointmentDirections.filter(
        direction =>
          appointments[direction].equipmentTypeId && appointments[direction].appointmentTypeId
      )

      if (activeDirections.length) {
        let selectedDate = date

        if (selectedDate instanceof Date) {
          selectedDate = selectedDate.toISOString()
        }

        const [timeSlotResults, status] = await facilityService.availableTimeSlots(
          facility.id,
          selectedDate,
          appointments[activeDirections[0]].equipmentTypeId,
          appointments[activeDirections[0]].appointmentTypeId,
          handlingMethod
        )

        const allAvailableTimeSlots = Array.isArray(timeSlotResults)
          ? timeSlotResults.map(timeSlots => {
              if (status !== 200) {
                fancyToast(timeSlots, status)
                return []
              }
              return timeSlots
            })
          : []

        setAvailableTimes(
          allAvailableTimeSlots.map(time => {
            const timeSlot = LuxonDateTime.fromISO(time.timeSlot, { setZone: true })
            return {
              id: timeSlot.toISO(),
              label: timeSlot.toFormat('hh:mm a'),
              disabled: handlingMethod === LIVE && !time.available
            }
          })
        )
      }
    },
    [handlingMethod, facility.id, appointmentDirections, appointments]
  )

  const isDateAvailable = date => {
    return availableDates.some(availableDate => {
      const available = new Date(availableDate)
      return (
        date.getFullYear() === available.getFullYear() &&
        date.getMonth() === available.getMonth() &&
        date.getDate() === available.getDate()
      )
    })
  }

  useEffect(() => {
    fetchAvailableDates()
  }, [fetchAvailableDates])

  useEffect(() => {
    if (facility?.id) {
      fetchAvailableTimes(getCurrentArrivalTime())
    }
  }, [
    appointments[OUTBOUND].arrivalTime,
    appointments[INBOUND].arrivalTime,
    facility?.id,
    handlingMethod,
    appointments[OUTBOUND].equipmentTypeId,
    appointments[INBOUND].equipmentTypeId
  ])

  const getCurrentArrivalTime = () => {
    if (appointmentDirections.includes(INBOUND) && appointmentDirections.includes(OUTBOUND)) {
      return appointments[INBOUND].arrivalTime || appointments[OUTBOUND].arrivalTime
    } else if (appointmentDirections.includes(INBOUND)) {
      return appointments[INBOUND].arrivalTime
    } else if (appointmentDirections.includes(OUTBOUND)) {
      return appointments[OUTBOUND].arrivalTime
    }
    return null
  }

  const getDayValue = () => {
    const currentArrivalTime = getCurrentArrivalTime()

    if (currentArrivalTime && currentArrivalTime instanceof Date) {
      return LuxonDateTime.fromJSDate(currentArrivalTime)
        .set({
          hour: 0,
          minute: 0
        })
        .toJSDate()
    } else if (currentArrivalTime) {
      const selectedDay = LuxonDateTime.fromISO(currentArrivalTime, { setZone: true })
      return new Date(selectedDay.year, selectedDay.month - 1, selectedDay.day)
    }

    return null
  }

  const getTimeValue = () => {
    const currentArrivalTime = getCurrentArrivalTime()
    if (
      !currentArrivalTime ||
      (type === CREATE && !handleTimeChangeHasBeenExecuted) ||
      !isTimeSet
    ) {
      return [{ id: '', label: '' }]
    }

    const arrivalTime =
      currentArrivalTime instanceof Date
        ? LuxonDateTime.fromJSDate(currentArrivalTime).setZone(TIMEZONE)
        : LuxonDateTime.fromISO(currentArrivalTime, { setZone: true })

    return [{ id: arrivalTime.toISO(), label: arrivalTime.toFormat('hh:mm a') }]
  }

  return (
    <Block
      display={
        (appointments[INBOUND].purchaseOrdersAttributes.length === 0 &&
          appointments[OUTBOUND].purchaseOrdersAttributes.length === 0) ||
        (!appointments[INBOUND].equipmentTypeId && !appointments[OUTBOUND].equipmentTypeId)
          ? 'none'
          : 'block'
      }
      flexDirection="column">
      <FlexGrid flexGridColumnCount={[1, 1, 2]} flexGridColumnGap="scale800" width="100%">
        <FlexGridItem>
          <FormControl>
            <DatePicker
              value={getDayValue()}
              placeholder=""
              onChange={({ date }) => handleDateChange(date)}
              label={t('Appointments.CreateAppointmentModal.Fields.ArrivalDate.Label.Text')}
              formatString="MM/dd/yyyy"
              filterDate={isDateAvailable}
              aria-label="appointment-available-dates"
              disabled={
                appointments[INBOUND].purchaseOrdersAttributes.length === 0 &&
                appointments[OUTBOUND].purchaseOrdersAttributes.length === 0
              }
            />
          </FormControl>
        </FlexGridItem>
        <FlexGridItem>
          <FormControl>
            <Select
              label={t('Appointments.CreateAppointmentModal.Fields.ArrivalTime.Label.Text')}
              options={availableTimes}
              aria-label="available-time-slots"
              onChange={({ option }) => handleTimeChange(option.id)}
              clearable={false}
              value={getTimeValue()}
              placeholder="Select time"
              disabled={
                !getCurrentArrivalTime() ||
                (appointments[INBOUND].purchaseOrdersAttributes.length === 0 &&
                  appointments[OUTBOUND].purchaseOrdersAttributes.length === 0)
              }
            />
          </FormControl>
        </FlexGridItem>
      </FlexGrid>
    </Block>
  )
}
