import { DriversCheckinContextProps } from 'components/contexts/drivers-checkin.context'

export const SET_LOADING = 'SET_LOADING'
export const SET_SHOW_FACILITY_NOT_FOUND_ERROR = 'SET_SHOW_FACILITY_NOT_FOUND_ERROR'
export const SET_SHOW_NO_ACTIVE_FACILITY_ERROR = 'SET_SHOW_NO_ACTIVE_FACILITY_ERROR'
export const SET_PATHNAME = 'SET_PATHNAME'
export const SET_SELECT_FACILITY = 'SET_SELECT_FACILITY_AND_'
export const SET_FACILITY_OPTIONS = 'SET_FACILITY_OPTIONS'
export const SET_OPTION = 'SET_OPTION'
export const SET_SEARCH = 'SET_SEARCH'
export const SET_SEARCHING = 'SET_SEARCHING'
export const SET_APPOINTMENTS = 'SET_APPOINTMENTS'
export const SET_SELECTED_APPOINTMENT = 'SET_SELECTED_APPOINTMENT'
export const SET_CHECKIN_FORM = 'SET_CHECKIN_FORM'
export const SET_SHIPPER = 'SET_SHIPPER'
export const SET_INPUT_SEARCH_LABEL = 'SET_INPUT_SEARCH_LABEL'
export const SET_SELECT_FACILITY_AND_HIDE_SELECT = 'SET_SELECT_FACILITY_AND_HIDE_SELECT'

export const driversCheckinReducer = (state: DriversCheckinContextProps, action) => {
  switch (action.type) {
    case SET_SHIPPER: {
      return {
        ...state,
        shipper: action.payload
      }
    }
    case SET_PATHNAME: {
      return {
        ...state,
        pathname: action.payload
      }
    }
    case SET_OPTION: {
      return {
        ...state,
        option: action.payload
      }
    }
    case SET_LOADING: {
      return {
        ...state,
        searchAppointment: {
          ...state.searchAppointment,
          loading: action.payload
        }
      }
    }
    case SET_SHOW_FACILITY_NOT_FOUND_ERROR: {
      return {
        ...state,
        searchAppointment: {
          ...state.searchAppointment,
          showFacilityNotFoundError: action.payload
        }
      }
    }
    case SET_INPUT_SEARCH_LABEL: {
      return {
        ...state,
        searchAppointment: {
          ...state.searchAppointment,
          inputSearchLabel: action.payload
        }
      }
    }
    case SET_SHOW_NO_ACTIVE_FACILITY_ERROR: {
      return {
        ...state,
        searchAppointment: {
          ...state.searchAppointment,
          showNoActiveFacilityError: action.payload
        }
      }
    }
    case SET_SELECT_FACILITY: {
      return {
        ...state,
        searchAppointment: {
          ...state.searchAppointment,
          selectedFacility: action.payload
        }
      }
    }
    case SET_SELECT_FACILITY_AND_HIDE_SELECT: {
      return {
        ...state,
        searchAppointment: {
          ...state.searchAppointment,
          selectedFacility: action.payload,
          hideSelect: true
        }
      }
    }
    case SET_FACILITY_OPTIONS: {
      return {
        ...state,
        searchAppointment: {
          ...state.searchAppointment,
          facilityOptions: action.payload
        }
      }
    }
    case SET_SEARCH: {
      return {
        ...state,
        search: action.payload
      }
    }
    case SET_SEARCHING: {
      return {
        ...state,
        searching: action.payload
      }
    }
    case SET_APPOINTMENTS: {
      return {
        ...state,
        appointments: action.payload
      }
    }
    case SET_SELECTED_APPOINTMENT: {
      return {
        ...state,
        selectedAppointment: action.payload
      }
    }
    case SET_CHECKIN_FORM: {
      return {
        ...state,
        checkinForm: action.payload
      }
    }

    default:
      return state
  }
}

export default driversCheckinReducer
