import PremiumCalendar from 'components/premium-calendar'
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid'
import React, { useEffect, useRef, useContext, useState } from 'react'
import { connectHits } from 'react-instantsearch-dom'
import rrulePlugin from '@fullcalendar/rrule'
import scrollGridPlugin from '@fullcalendar/scrollgrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction'
import momentPlugin from '@fullcalendar/moment'
import { CalendarReferenceContext } from 'components/contexts/calendar-reference-context'
import TimeFormatter from 'components/utils/time-formatter'
import { BLACKOUT_TYPE } from 'components/constants/blackout-type'
import useEvents, {
  APPOINTMENT_TYPE,
  DOCK_ASSIGNMENT
} from '../../shipper/instant-calendar/hooks/use-events'
import { CurrentUserContext } from '../../homepage/current-user-context'
import InstantDatePicker from '../../shipper/instant-calendar/toggles/instant-date-picker'
import CalendarPopover from '../../shipper/instant-calendar/calendar-popover'
import { fancyToast } from '../../utils'
import {
  DEFAULT_UNASSIGNED_DOCK_OPTION,
  FIXED_UNASSIGNED_COLUMN,
  useDockAssigmentContext
} from '../../contexts/dock-assigment.context'
import {
  DEFAULT_SLOT_DURATIONS,
  formatByAppointmentType
} from '../../shipper/instant-calendar/hooks/use-facility-info'
import { useTranslation } from 'react-i18next'
import { StatusCodes } from '../../constants/http-status-codes'
import { appointmentService } from '../../services'
import EventTile from 'components/ui/specific/EventTile'
import { getDateFilterFormat } from '../../shipper/instant-calendar/calendar-hits'
import { RefreshSearchContext } from '../../../lib/cyber-components/search/search'
import _isEqual from 'lodash/isEqual'
import StyledSpinner from '../../shared/styled-spinner'
import { Block } from 'baseui/block'
import useBlackouts from '../../shipper/instant-calendar/hooks/use-blackouts'
import DockBlackoutModal from './DockBlackoutModal'
import DockAssignmentConfirmationModal from './DockAssignmentConfirmationModal'

export const NonConnectedDockAssignmentCalendar = ({
  hits,
  setSelectedEvent,
  timeRange,
  selectedDate,
  setSelectedDate,
  setNumberOfHits
}) => {
  const { calendarReference, setDateInCalendarFilter, setCacheDate, cacheDate } =
    useContext(CalendarReferenceContext)
  const { currentUser } = useContext(CurrentUserContext)
  const {
    dockOptions,
    selectedDocks,
    selectedFacility,
    loading,
    blackoutRefresh,
    actions: {
      setLoading,
      setSelectedDockAndTimeForBlackoutModal,
      openBlackoutModalForUpdate,
      setConfirmationModal
    }
  } = useDockAssigmentContext()
  const { t } = useTranslation()
  const { refresh } = useContext(RefreshSearchContext)

  const fixedUnassignedColumn = {
    ...FIXED_UNASSIGNED_COLUMN,
    title: t(FIXED_UNASSIGNED_COLUMN.title)
  }

  // We need to cache hits so we have more control over them on dock assigment updates
  const [cacheHits, setCacheHits] = useState(hits)

  // This is in charge of updating the cache only if hits are different from what cache already has
  // this way we avoid updating the calendar event's state on every props change or render
  useEffect(() => {
    if (!_isEqual(hits, cacheHits) && hits.length > 0) {
      setCacheHits(hits)
    }
  }, [hits])

  // On click setCompatibleDocks, selectedDocks change, so we need to refresh the appointments shown in calendar
  useEffect(() => {
    refresh()
    cleanEvents()
  }, [selectedDocks])

  const cleanEvents = () =>
    calendarReference.current && calendarReference.current.getApi().removeAllEvents()

  const events = useEvents(
    cacheHits,
    {
      [selectedFacility?.id]: {
        ...(selectedFacility || {}),
        appointmentDurationsHash:
          selectedFacility &&
          formatByAppointmentType(
            selectedFacility.appointmentPreference?.appointmentDurationsAttributes
          )
      }
    },
    DOCK_ASSIGNMENT
  )

  const [showDatepicker, setShowDatepicker] = useState(false)

  const blackouts = useBlackouts({
    selectedEvent: null,
    selectedFacilities: [selectedFacility],
    dockAssigment: true,
    blackoutRefresh
  })

  const setDateAndCache = (newDate: Date) => {
    setSelectedDate(newDate)
    const dateFormatted = getDateFilterFormat(newDate)
    setDateInCalendarFilter(dateFormatted)
    setCacheDate({
      ...cacheDate,
      [currentUser.shipperId]: {
        ...((cacheDate && cacheDate[currentUser.shipperId]) || {}),
        [currentUser.id]: dateFormatted
      }
    })
  }

  const handleCustomPrevClick = () => {
    const newDate = selectedDate
    newDate.setDate(newDate.getDate() - 1)
    setDateAndCache(newDate)
  }

  const handleCustomTodayClick = () => {
    setDateAndCache(new Date())
  }

  const handleCustomNextClick = () => {
    const newDate = selectedDate
    newDate.setDate(newDate.getDate() + 1)
    setDateAndCache(newDate)
  }

  const datepicker = () => {
    setShowDatepicker(!showDatepicker)
  }

  const selectedDateRef = useRef(selectedDate)

  useEffect(() => {
    function filterHitsByRange(hits, timeRange) {
      const minTimeHours = parseInt(timeRange.minTime[0].label.split(':')[0], 10)
      const minTimeMinutes = parseInt(timeRange.minTime[0].label.split(':')[1], 10)
      const maxTimeHours = parseInt(timeRange.maxTime[0].label.split(':')[0], 10)
      const maxTimeMinutes = parseInt(timeRange.maxTime[0].label.split(':')[1], 10)

      const filteredHits = hits.filter(hit => {
        const arrivalTime = new Date(hit.arrival_time * 1000)
        const arrivalHours = arrivalTime.getHours()
        const arrivalMinutes = arrivalTime.getMinutes()

        return (
          (arrivalHours > minTimeHours ||
            (arrivalHours === minTimeHours && arrivalMinutes >= minTimeMinutes)) &&
          (arrivalHours < maxTimeHours ||
            (arrivalHours === maxTimeHours && arrivalMinutes < maxTimeMinutes))
        )
      })

      return filteredHits
    }
    selectedDateRef.current = selectedDate
    if (timeRange.maxTime[0].label !== '24:00' || timeRange.minTime[0].label !== '00:00') {
      const filteredHits = filterHitsByRange(hits, timeRange)
      setNumberOfHits(filteredHits.length)
    } else {
      setNumberOfHits(hits.length)
    }
  }, [selectedDate, timeRange, hits, setNumberOfHits])

  const checkAppointmentOverlap = (
    appointment: { start: Date; end: Date },
    appResourceId: string
  ) => {
    const filteredBlackouts = blackouts.filter(b => b.resourceId === appResourceId)

    for (const blackout of filteredBlackouts) {
      const blackoutStart = new Date(blackout.start)
      const blackoutEnd = new Date(blackout.end)

      // Appointment start time is contained in blackout range
      const appointmentStartIsAfterExceptionStart = appointment.start >= blackoutStart
      const appointmentStartIsBeforeExceptionEnd = appointment.start < blackoutEnd

      // Appointment end time is contained in blackout range
      const appointmentEndIsAfterExceptionStart = appointment.end > blackoutStart
      const appointmentEndIsBeforeExceptionEnd = appointment.end <= blackoutEnd

      // blackout is contained in appointment
      const blackoutStartAfterAppintmentStart = appointment.start <= blackoutStart
      const blackoutEndBeforeAppintmentEnds = appointment.end >= blackoutEnd

      if (
        (appointmentStartIsAfterExceptionStart && appointmentStartIsBeforeExceptionEnd) ||
        (appointmentEndIsAfterExceptionStart && appointmentEndIsBeforeExceptionEnd) ||
        (blackoutStartAfterAppintmentStart && blackoutEndBeforeAppintmentEnds)
      ) {
        return true
      }
    }

    return false
  }

  const eventDrop = info => {
    if (currentUser.viewOnly) {
      info.revert()
      return
    }
    const resourceId = info?.newResource?._resource.id
    const { equipmentTypeId, appointmentTypeId, commodityType, type } =
      info.event._def.extendedProps
    const dock = dockOptions.filter(d => d.id === resourceId)[0]
    const sharedCapacity = selectedFacility.appointmentPreference.equipmentCapacityShared

    if (type === BLACKOUT_TYPE) {
      fancyToast({ info: t('DockAssignment.Errors.BlackoutDragAndDrop') }, 500)
      info.revert()
      return
    }

    if (
      resourceId &&
      resourceId !== fixedUnassignedColumn.id &&
      !sharedCapacity &&
      !dock.appointmentTypeIds.includes(appointmentTypeId)
    ) {
      fancyToast({ info: t('DockAssignment.Errors.AppointmentType') }, 500)
      info.revert()
      return
    }

    if (
      resourceId &&
      resourceId !== fixedUnassignedColumn.id &&
      !dock.commodityTypes.includes(commodityType)
    ) {
      fancyToast({ info: t('DockAssignment.Errors.CommodityType') }, 500)
      info.revert()
      return
    }

    if (
      resourceId &&
      resourceId !== fixedUnassignedColumn.id &&
      !dock.equipmentTypeIds.includes(equipmentTypeId)
    ) {
      fancyToast({ info: t('DockAssignment.Errors.EquipmentType') }, 500)
      info.revert()
      return
    }

    if (type === APPOINTMENT_TYPE && checkAppointmentOverlap(info.event, resourceId)) {
      setConfirmationModal(true, () => {
        assignDock(info, resourceId)
      })
      return
    }

    assignDock(info, resourceId)
  }

  const assignDock = (info, resourceId) => {
    setLoading(true)

    appointmentService
      .assignDock(
        info.event._def.publicId,
        resourceId || info.event._def.resourceIds[0],
        info.event.start.toISOString()
      )
      .then(([_, status]) => {
        if (status >= StatusCodes.NO_CONTENT) {
          fancyToast({ info: t('DockAssignment.Errors.General') }, 500)
          info.revert()
          return
        } else {
          fancyToast(
            {
              info: t(
                `DockAssignment.${
                  resourceId !== fixedUnassignedColumn.id ? 'Success' : 'SuccessUnassigned'
                }`
              )
            },
            200
          )
        }

        // We need to update the appointment in the cache, so it is moved to the correct dock resource
        // and persisted in every following render.
        const updatedCacheHits = cacheHits.map(item => {
          if (item.id === info.event._def.publicId) {
            return {
              ...item,
              dockId: resourceId || info.event._def.resourceIds[0],
              _def: { ...item._def, resourceIds: [resourceId || info.event._def.resourceIds[0]] },
              dock_time:
                resourceId === fixedUnassignedColumn.id
                  ? new Date(item.arrival_time * 1000)
                  : info.event.start
            }
          }
          return item
        })

        cleanEvents()
        setCacheHits(updatedCacheHits)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const dateClick = ({ date, resource }: DateClickArg) => {
    if (resource._resource.id !== FIXED_UNASSIGNED_COLUMN.id && !currentUser.viewOnly) {
      setSelectedDockAndTimeForBlackoutModal({
        dockId: resource._resource.id,
        arrivalTime: date
      })
    }
  }

  return loading ? (
    <Block display="flex" justifyContent="center" alignItems="center" height="500px">
      <StyledSpinner />
    </Block>
  ) : (
    <div className="day-calendar">
      <CalendarPopover isOpen={showDatepicker} togglePopover={setShowDatepicker}>
        <InstantDatePicker attribute="arrival_time" />
      </CalendarPopover>
      <PremiumCalendar
        height="74vh"
        innerRef={calendarReference}
        editable={!currentUser?.viewOnly}
        eventStartEditable={true}
        eventResizableFromStart={false}
        eventDurationEditable={false}
        eventOverlap={(stillEvent, movingEvent) => {
          if (stillEvent._def.resourceIds[0] === fixedUnassignedColumn.id) {
            return true
          }
          if (
            stillEvent.extendedProps.type === BLACKOUT_TYPE &&
            movingEvent.extendedProps.type === APPOINTMENT_TYPE
          ) {
            return true
          }
          return false
        }}
        eventDrop={eventDrop}
        plugins={[
          rrulePlugin,
          timeGridPlugin,
          interactionPlugin,
          momentPlugin,
          scrollGridPlugin,
          resourceTimeGridPlugin
        ]}
        slotDuration={
          DEFAULT_SLOT_DURATIONS[selectedFacility?.appointmentDuration || 1800] || '00:30'
        }
        slotLabelFormat={
          {
            hourCycle: 'h23',
            hour: '2-digit',
            minute: '2-digit'
          } as any
        }
        initialView="resourceTimeGridDay"
        allDaySlot={false}
        dayMinWidth={220}
        customButtons={{
          customPrevButton: {
            icon: 'chevron-left',
            click: handleCustomPrevClick
          },
          customTodayButton: {
            text: t('DockAssignment.Calendar.Today'),
            click: handleCustomTodayClick
          },
          customNextButton: {
            icon: 'chevron-right',
            click: handleCustomNextClick
          },
          datepicker: {
            text: '',
            click: datepicker
          }
        }}
        headerToolbar={{
          left: 'title datepicker customPrevButton customTodayButton customNextButton',
          center: '',
          right: ''
        }}
        events={[...events, ...blackouts]}
        eventContent={({ event }) => {
          return <EventTile event={event} />
        }}
        eventClick={({ event }) => {
          const selectedEvent: SelectedEvent = {
            id: event.id,
            blackout: event._def?.extendedProps?.type === BLACKOUT_TYPE
          }
          setSelectedEvent(selectedEvent)
          if (event._def?.extendedProps?.type === BLACKOUT_TYPE) {
            openBlackoutModalForUpdate({ id: event.id })
          }
          return false
        }}
        dateClick={dateClick}
        titleFormat={{
          month: 'short',
          weekday: 'short',
          day: 'numeric'
        }}
        nowIndicator
        slotMinTime={`${timeRange && timeRange.minTime[0].label}:00`}
        slotMaxTime={`${timeRange && timeRange.maxTime[0].label}:00`}
        resourceOrder="position"
        resources={
          (selectedDocks.length > 1 ||
            selectedDocks[0]?.id !== DEFAULT_UNASSIGNED_DOCK_OPTION.id) &&
          selectedFacility
            ? [
                fixedUnassignedColumn,
                ...selectedDocks?.map((option, idx) => ({
                  id: option.id,
                  title: option.label,
                  position: idx + 1
                }))
              ]
            : selectedFacility
            ? [
                fixedUnassignedColumn,
                ...dockOptions
                  .filter(option => option.id !== DEFAULT_UNASSIGNED_DOCK_OPTION.id)
                  .map((option, idx) => ({
                    id: option.id,
                    title: option.label,
                    position: idx + 1
                  }))
              ]
            : []
        }
        dayHeaderContent={({ date }) => {
          return new TimeFormatter('humanDate').format(date)
        }}
      />
      <DockBlackoutModal />
      <DockAssignmentConfirmationModal />
    </div>
  )
}

const DockAssignmentCalendar = connectHits(NonConnectedDockAssignmentCalendar) as any

export default DockAssignmentCalendar
