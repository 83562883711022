import { DEFAULT_EXTERNAL_USER_ROLE } from '../constants/default-external-user-role'
import { DEFAULT_INTERNAL_USER_ROLE } from '../constants/default-internal-user-role'
import { SHIPPER } from '../models/Role'

export const getQuestionPermissions = ({ question, currentUser, userType }) => {
  if (!currentUser) {
    return Array.isArray(question.questionPermissionsAttributes) &&
      question.questionPermissionsAttributes.length > 0
      ? question.questionPermissionsAttributes[0]
      : DEFAULT_EXTERNAL_USER_ROLE
  }

  const permission = question.questionPermissionsAttributes.filter(
    qpa =>
      qpa.userRole?.name === currentUser?.userType || qpa.userRoleId === currentUser?.userRoleId
  )[0]

  if (permission) {
    return permission
  }

  return userType === SHIPPER ? DEFAULT_INTERNAL_USER_ROLE : DEFAULT_EXTERNAL_USER_ROLE
}
