import styled from 'styled-components'
import { primitives } from '../../homepage/custom-theme'

export const EventTileContainer = styled.div<{ yardScheduleView: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ yardScheduleView }) => (yardScheduleView ? '2px' : '0px')};
`

export const EventTileWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
  flex-wrap: wrap;
`

export const EventTileTop = styled.div`
  display: flex;
  gap: 2px 4px;
  align-items: baseline;
  overflow: hidden;
  flex-wrap: wrap;
  flex: 1;
`

export const EventTileNoBlackoutContainer = styled.div`
  display: flex;
  gap: 2px 4px;
  text-wrap: nowrap;
  flex-wrap: wrap;
  overflow: hidden;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`
export const EventTileTitle = styled.span<{ yardScheduleView: boolean }>`
  ${({ yardScheduleView }) =>
    yardScheduleView
      ? `
      width: 90px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      transform: translateY(-2px);
    `
      : ''}
`

export const DockOrSlotName = styled.span`
  color: ${primitives.primary};
  font-weight: 'bolder';
`

export const TrailerIcon = styled.div`
  transform: translateY(4px);
  margin-right: 4px;
  display: inline-block;
`

export const ConfirmationIdContainer = styled.span`
  display: inline-block;
  min-width: 95px;
  transform: translateY(2px);
`

export const YardAppointmentTime = styled.span`
  color: ${primitives.primary};
`

export const DriverAvatarOverrides = {
  Initials: {
    style: ({}) => ({
      fontSize: '6px'
    })
  },
  Root: {
    style: () => ({
      marginLeft: '5px',
      transform: 'translateY(-1.5px)'
    })
  }
}

export const YardJockeyAvatarOverrides = {
  Initials: {
    style: ({}) => ({
      fontSize: '6px'
    })
  },
  Root: {
    style: () => ({
      marginLeft: '5px',
      transform: 'translateY(-1.5px)'
    })
  }
}

export const EventTileStatefulTooltipOverrides = {
  Inner: {
    style: ({ $theme }) => ({
      paddingTop: $theme.sizing.scale600,
      paddingBottom: $theme.sizing.scale600,
      paddingLeft: $theme.sizing.scale600,
      paddingRight: $theme.sizing.scale600,
      backgroundColor: $theme.colors.backgroundPrimary,
      color: $theme.colors.contentPrimary
    })
  }
}

export const TaskTimeTagOverrides = {
  StartEnhancerContainer: {
    style: ({ $theme }) => ({
      paddingRight: $theme.sizing.scale100
    })
  }
}

export const AppointmentTypeName = styled.span<{ showIcon: boolean }>`
  font-size: 9px;
  min-width: 2em;
  ${({ showIcon }) => showIcon && 'margin-right: 5px'}
`
