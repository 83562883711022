import { Block } from 'baseui/block'
import { ParagraphMedium } from 'baseui/typography'
import React, { useContext, useEffect, useRef } from 'react'
import Button from 'components/ui/generic/Button'
import { CallBell, CaretLeft } from '@phosphor-icons/react'
import { primitives } from 'components/homepage/custom-theme'
import { useHistory } from 'react-router-dom'
import FormControl from 'components/ui/generic/FormControl'
import Input from 'components/ui/generic/Input'
import { DriversCheckinContext } from 'components/contexts/drivers-checkin.context'
import { useCQStateValue } from 'components/contexts/custom-questions.context'
import { Answer } from 'components/appointments/types'
import QuestionsList from 'components/facilities/book/additional-information/QuestionsList'
import checkAnswersRequireness from 'components/utils/answer-requireness-validation'
import { ErrorMessageButton } from 'components/components/ErrorMessageButton'
import { answerService } from 'components/services'
import DriversCheckinConfirmation from './DriversCheckinConfirmation'
import DriversNavbar from '../DriversNavbar'
import { DRIVER } from 'components/models/User'
import { useTranslation } from 'react-i18next'

const filterPreCheckinQuestions = (answers: Answer[]) => {
  return answers.filter(
    answer =>
      !answer?.question?.questionPermissionsAttributes?.some(
        permission => permission?.postCheckin && permission?.userRole?.audience === DRIVER
      )
  )
}

const DriversCheckinForm = ({ match }) => {
  const { state, actions } = useContext(DriversCheckinContext)
  const { checkinForm, shipper, pathname } = state
  const { setCheckin, loadSelectedAppointment, setCheckinAppointment, checkin } = actions
  const history = useHistory()
  const { t } = useTranslation()

  const fileUpdateRef = useRef(null)

  const {
    questions,
    answers,
    temporalFiles,
    documentSelections,
    actions: { setAnswers, setQuestions, updateFileUploadState, resetTemporalFilesState }
  } = useCQStateValue()

  const loadAppointment = async (appointmentId: string) => {
    const appoitnment = await loadSelectedAppointment(appointmentId)
    if (!appoitnment) {
      history.push(`/`)
      return
    }
    loadAnswers(appoitnment)
  }

  const loadAnswers = (appointment: any) => {
    const getActive = filterPreCheckinQuestions(appointment.answersAttributes)
    setAnswers(getActive)
    setQuestions(getActive?.map(ans => ans?.question))
  }

  useEffect(() => {
    if (match?.params?.handle) {
      loadAppointment(match?.params?.handle)
    }
  }, [match?.params?.handle])

  const formErrors = () => {
    const errorValidations = [
      {
        label: t('DriversCheckin.DriversCheckinForm.FormValidations.DriverName.Text'),
        status: !!checkinForm.driverName
      },
      {
        label: t('DriversCheckin.DriversCheckinForm.FormValidations.DriverPhone.Text'),
        status: !!checkinForm.driverPhone
      },
      {
        label: t('DriversCheckin.DriversCheckinForm.FormValidations.TrailerNumber.Text'),
        status: !!checkinForm.trailerNumber
      },
      {
        label: t('DriversCheckin.DriversCheckinForm.FormValidations.Answer.Text'),
        status: !answers.some(e => e.error)
      }
    ]

    checkAnswersRequireness(answers, temporalFiles, documentSelections, errorValidations)

    return errorValidations
  }

  const handleSubmit = async () => {
    if (documentSelections.length) {
      documentSelections.forEach(({ documentId, questionId }) =>
        answerService.removeFile(documentId, questionId)
      )
    }

    if (temporalFiles.length) {
      await updateFileUploadState(true)
      fileUpdateRef.current = true
    } else {
      fileUpdateRef.current = false
      checkin()
    }
  }

  useEffect(() => {
    if (
      temporalFiles.length &&
      temporalFiles.every(tf => tf?.uploadState === 'saved') &&
      fileUpdateRef.current
    ) {
      resetTemporalFilesState()
      checkin()
    }
  }, [answers, temporalFiles])

  return (
    <Block width={'100%'} display={'flex'} justifyContent={'center'}>
      <Block width={['100%', '100%', '535px', '535px']}>
        <ParagraphMedium overrides={{ Block: { style: { color: primitives.primary } } }}>
          {t('DriversCheckin.DriversCheckinForm.Title.Text')}
        </ParagraphMedium>
        <Block display={'flex'} flexDirection={'column'} gridColumnGap={'10px'}>
          <FormControl>
            <Input
              onChange={e => {
                setCheckin({ ...checkinForm, driverName: e.currentTarget.value })
              }}
              value={checkinForm.driverName || ''}
              label={t('DriversCheckin.DriversCheckinForm.Fields.DriverName.Text')}
            />
          </FormControl>
          <FormControl>
            <Input
              onChange={e => {
                setCheckin({ ...checkinForm, driverPhone: e.currentTarget.value })
              }}
              value={checkinForm.driverPhone || ''}
              label={t('DriversCheckin.DriversCheckinForm.Fields.DriverPhone.Text')}
            />
          </FormControl>
          <FormControl>
            <Input
              onChange={e => {
                setCheckin({ ...checkinForm, trailerNumber: e.currentTarget.value })
              }}
              value={checkinForm.trailerNumber || ''}
              label={t('DriversCheckin.DriversCheckinForm.Fields.TrailerNumber.Text')}
            />
          </FormControl>
        </Block>
        <Block>
          {questions && questions.length > 0 ? (
            <QuestionsList
              appointment={checkinForm.appointment}
              setAppointment={updatedAnswer => {
                setCheckinAppointment({
                  ...checkinForm.appointment,
                  answersAttributes: updatedAnswer
                })
              }}
              showErrors
            />
          ) : null}
        </Block>
        <Block width={'100%'} display={'flex'} justifyContent={'end'} gridColumnGap={'5px'}>
          <Button
            startEnhancer={<CaretLeft size={16} />}
            onClick={() => {
              history.push(pathname)
            }}>
            {t('DriversCheckin.DriversCheckinForm.Actions.Back.Text')}
          </Button>
          <ErrorMessageButton
            onClick={() => {
              handleSubmit()
            }}
            endEnhancer={<CallBell size={16} />}
            label={t('DriversCheckin.DriversCheckinForm.Actions.Submit.Text', {
              shipper: shipper?.name || ''
            })}
            errors={formErrors()}
            isLoading={checkinForm.loading}
          />
        </Block>
      </Block>
    </Block>
  )
}

const ContainerDriversCheckinForm = ({ match }) => {
  const { state } = useContext(DriversCheckinContext)
  const { checkinForm } = state
  if (checkinForm.checkedIn) {
    return <DriversCheckinConfirmation />
  }
  return (
    <DriversNavbar>
      <DriversCheckinForm match={match} />
    </DriversNavbar>
  )
}
export default ContainerDriversCheckinForm
