import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import Button from 'components/ui/generic/Button'

import { useCQStateValue } from 'components/contexts/custom-questions.context'
import FileUploader from 'components/shared/file-uploader'
import { TooltipBox, TooltipCard, TooltipText } from 'components/shared/styles/CustomTooltip.styled'
import { fancyToast } from 'components/utils'
import { parseLabelMaxChars } from 'components/utils/label-char-limit-formatter'
import { getFileName } from 'components/utils/parse-filename'
import React, { ReactElement, useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import Questions from '../../../../components/Questions/Questions'
import { DOCUMENT_TYPE } from '../../../../models/Question'
import TemporalFile from '../../../components/temporalFile'
import { BaseFileContainer, FileNameLabel } from '../styled/TemporalFile.styled'
import { AnsweredQuestionInterface } from './answers'
import { checkQuestionError } from '../../../../utils/check-question-error'
import formatQuestionLabel from '../../../../utils/format-question-label'
import FormControl from 'components/ui/generic/FormControl'
import { useTranslation } from 'react-i18next'
import { CheckCircle, Trash } from '@phosphor-icons/react'

export const AnswerFile = ({ props, onRemoveFile, selectedForRemove }) => {
  return (
    <BaseFileContainer isAnswer selectedForRemove={selectedForRemove}>
      <TooltipCard>
        <TooltipText>
          {selectedForRemove ? (
            <FileNameLabel>
              Update appointment to delete <strong>{parseLabelMaxChars(props?.name, 14)}</strong>
            </FileNameLabel>
          ) : (
            <a href={props?.url}>
              <FileNameLabel>
                <strong>{parseLabelMaxChars(props?.name, 22)}</strong>
              </FileNameLabel>
            </a>
          )}
        </TooltipText>
        <TooltipBox>
          <p style={{ wordBreak: 'break-all', fontSize: '12px' }}>
            {(props?.name).replace(/%20/g, ' ')}
          </p>
        </TooltipBox>
      </TooltipCard>

      {!selectedForRemove && (
        <>
          <CheckCircle size={16} color="#0C8564" />
          <Button kind="tertiary" onClick={() => onRemoveFile(true)}>
            <Trash size={16} data-testid={`answer-file-delete-icon-${props?.id}`} />
          </Button>
        </>
      )}
    </BaseFileContainer>
  )
}

const AnsweredQuestion = ({
  answeredQuestion,
  record,
  setRecord,
  answeredQuestions,
  setAnsweredQuestions,
  shouldValidate
}: {
  answeredQuestion: AnsweredQuestionInterface
  record?: any
  setRecord?: (appointment: any) => void
  answeredQuestions: AnsweredQuestionInterface[]
  setAnsweredQuestions: (answer: AnsweredQuestionInterface[]) => void
  shouldValidate?: boolean
}): ReactElement => {
  const { id, prompt, readOnly, attachedDocuments, questionId, type, permission } = answeredQuestion
  const [loading, setLoading] = useState<boolean>(false)
  const [previewData, setPreviewData] = useState<any>(null)
  const responsiveUploader = useMediaQuery({
    query: `(max-width: 1200px)`
  })

  const {
    temporalFiles,
    documentSelections,
    actions: { updateAnswer, removeTemporalFile, addFileSelection }
  } = useCQStateValue()

  const { t } = useTranslation()

  const setAnswer = answered => {
    if (setRecord && record) {
      const newAnsweredQuestions = answeredQuestions

      const answeredQuestionIndex = newAnsweredQuestions.findIndex(obj => obj.id === id)

      newAnsweredQuestions[answeredQuestionIndex] = {
        ...answeredQuestions[answeredQuestionIndex],
        response: answered.response
      }

      const error = checkQuestionError(
        newAnsweredQuestions[answeredQuestionIndex].question,
        permission,
        newAnsweredQuestions[answeredQuestionIndex]
      )

      newAnsweredQuestions[answeredQuestionIndex].error = error

      setAnsweredQuestions(newAnsweredQuestions)

      setRecord({
        ...record,
        answersAttributes: [
          ...record.answersAttributes.filter(answer => answer.id !== id),
          {
            ...answeredQuestion,
            response: answered.response,
            error
          }
        ]
      })

      updateAnswer({
        questionId,
        response: answered.response,
        error
      })
    }
  }

  useEffect(() => {
    setPreviewData(temporalFiles.filter(tf => tf?.questionId === questionId)[0]?.files)
  }, [temporalFiles])

  return (
    <FlexGrid flexGridColumnCount={[1, 1, 2]} flexGridColumnGap="scale200">
      <FlexGridItem>
        <FormControl
          label={shouldValidate ? formatQuestionLabel(answeredQuestion.permission, prompt) : prompt}
          caption={`${
            answeredQuestion.question.minLength
              ? `Min length: ${answeredQuestion.question.minLength}`
              : ''
          }${
            answeredQuestion.question.maxLength
              ? ` - Max length: ${answeredQuestion.question.maxLength}`
              : ''
          }${answeredQuestion.question.numericOnly ? ' - numeric' : ''}`}>
          {type === DOCUMENT_TYPE ? (
            <FileUploader
              questionId={questionId}
              onError={() => fancyToast({ info: 'Error uploading file, try again later' }, 412)}
              multiple
              disabled={!permission.canEdit}
              fileCountObserver={e => {
                if (e + attachedDocuments?.length > 10) {
                  fancyToast({ info: 'Max. limit of files is 10' }, 412)
                }
              }}
              options={{
                addFile: true,
                maxWidth: responsiveUploader ? '240px' : '380px',
                type: 'document',
                customLabel: t('Common.FileUploader.Label.Text')
              }}
            />
          ) : (
            <Questions
              question={answeredQuestion.question}
              answer={answeredQuestion}
              displayError={shouldValidate}
              setAnswer={e => {
                setAnswer(e)
              }}
              disabled={readOnly || loading || !permission.canEdit}
              permission={permission}
            />
          )}
        </FormControl>
      </FlexGridItem>
      <FlexGridItem>
        {((type === 'document' && previewData) || attachedDocuments) && (
          <FormControl label={`Uploaded Files`}>
            <div />
          </FormControl>
        )}
        {type === 'document' &&
          previewData &&
          previewData.map(item => (
            <TemporalFile
              file={item?.file || item}
              onRemoveFile={e => {
                removeTemporalFile(questionId, e.id)
                fancyToast({ Info: 'Removing temporal file.' }, 200)
              }}
              isHidden={item?.isHidden}
            />
          ))}
        {attachedDocuments && (
          <div>
            {attachedDocuments.map(document => (
              <AnswerFile
                props={{
                  url: document?.documentSecureUrl,
                  name: getFileName(document?.documentSecureUrl).replace(/%20/g, ' '),
                  id: id
                }}
                onRemoveFile={() => {
                  addFileSelection(document?.id, answeredQuestion?.id)
                  fancyToast({ file: 'ready for deletion' }, 200)
                }}
                selectedForRemove={
                  documentSelections.find(selectedItem => selectedItem?.documentId === document?.id)
                    ? true
                    : false
                }
              />
            ))}
          </div>
        )}
      </FlexGridItem>
    </FlexGrid>
  )
}

export default AnsweredQuestion
