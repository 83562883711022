import Questions from '../../../components/Questions/Questions'
import React, { useContext } from 'react'
import { Answer, Question } from 'components/appointments/types'
import { TEXT_TYPE } from 'components/models/Question'
import regexValidations from 'components/utils/regex-validations'
import { CARRIER } from '../../../models/User'
import { CurrentUserContext } from '../../../homepage/current-user-context'
import { useTranslation } from 'react-i18next'

import FormControl from 'components/ui/generic/FormControl'
import { getQuestionPermissions } from '../../../utils/get-question-permissions'
import { SCHEDULER } from '../../../models/Role'

const generateEmailQuestion = (userType: string): Question => ({
  prompt: `${userType === CARRIER ? 'Carrier' : 'Scheduler'} Email`,
  answerType: TEXT_TYPE,
  minLength: 1,
  maxLength: 100,
  numericOnly: false,
  position: 2,
  questionPermissionsAttributes: []
})

const SchedulerEmail = ({ schedulerEmail, setSchedulerEmail }) => {
  const onChangeSchedulerEmail = (answer: Answer) => {
    setSchedulerEmail({
      ...schedulerEmail,
      email: answer.response,
      displayError: !regexValidations.email.test(answer.response)
    })
  }

  const answer: Answer = {
    response: schedulerEmail?.email
  }

  const { currentUser } = useContext(CurrentUserContext)

  const EMAIL_QUESTION = generateEmailQuestion(currentUser?.userType)
  const { t } = useTranslation()

  const permission = getQuestionPermissions({
    userType: SCHEDULER,
    currentUser,
    question: EMAIL_QUESTION
  })

  return (
    <FormControl>
      <Questions
        question={EMAIL_QUESTION}
        disabled={schedulerEmail?.disabled}
        answer={answer}
        setAnswer={answer => onChangeSchedulerEmail(answer)}
        displayError={schedulerEmail?.displayError}
        label={t('Scheduler.OpenScheduling.Steps.Fields.SchedulerEmail.Label.Text')}
        permission={permission}
      />
    </FormControl>
  )
}

export default SchedulerEmail
