import { DockType } from 'components/docks/types'
import { Dock } from 'components/models/Dock'
import { Slot } from 'components/models/Slot'

const FLAV = 'FLAV'
const CONC = 'CONC'
const CAN = 'CAN'
const END = 'END'
const WRAP = 'WRAP'
const TRAY = 'TRAY'
const PCK_OTH = 'PCK-OTH'
const MISC = 'MISC'
const DRY_RAW = 'DRY RAW'
const DEX = 'DEX'
const GFB = 'GFB'
const ELT = 'ELT'

const FLAV_LABEL = 'Flavor'
const CONC_LABEL = 'Juice'
const CAN_LABEL = 'Cans'
const END_LABEL = 'Can Ends'
const WRAP_LABEL = 'Wrapping'
const TRAY_LABEL = 'Can Trays'
const PCK_OTH_LABEL = 'Packaging - other'
const MISC_LABEL = 'Miscellaneous'
const DRY_RAW_LABEL = 'Dry Raw Ingredients'
const DEX_LABEL = 'Dextrose'
const GFB_LABEL = 'Gluten Free Base'
const ELT_LABEL = 'Ethyl lactate'

type CommodityType =
  | typeof FLAV
  | typeof CONC
  | typeof CAN
  | typeof END
  | typeof WRAP
  | typeof TRAY
  | typeof PCK_OTH
  | typeof MISC
  | typeof DRY_RAW
  | typeof DEX
  | typeof GFB
  | typeof ELT

type CommodityTypeOption = { id: CommodityType; label: string }

const FLAV_OPTION: CommodityTypeOption = { id: FLAV, label: FLAV_LABEL }
const CONC_OPTION: CommodityTypeOption = { id: CONC, label: CONC_LABEL }
const CAN_OPTION: CommodityTypeOption = { id: CAN, label: CAN_LABEL }
const END_OPTION: CommodityTypeOption = { id: END, label: END_LABEL }
const WRAP_OPTION: CommodityTypeOption = { id: WRAP, label: WRAP_LABEL }
const TRAY_OPTION: CommodityTypeOption = { id: TRAY, label: TRAY_LABEL }
const PCK_OTH_OPTION: CommodityTypeOption = {
  id: PCK_OTH,
  label: PCK_OTH_LABEL
}
const MISC_OPTION: CommodityTypeOption = { id: MISC, label: MISC_LABEL }
const DRY_RAW_OPTION: CommodityTypeOption = {
  id: DRY_RAW,
  label: DRY_RAW_LABEL
}
const DEX_OPTION: CommodityTypeOption = { id: DEX, label: DEX_LABEL }
const GFB_OPTION: CommodityTypeOption = { id: GFB, label: GFB_LABEL }
const ELT_OPTION: CommodityTypeOption = { id: ELT, label: ELT_LABEL }

const COMMODITY_TYPE_OPTIONS: CommodityTypeOption[] = [
  FLAV_OPTION,
  CONC_OPTION,
  CAN_OPTION,
  END_OPTION,
  WRAP_OPTION,
  TRAY_OPTION,
  PCK_OTH_OPTION,
  MISC_OPTION,
  DRY_RAW_OPTION,
  DEX_OPTION,
  GFB_OPTION,
  ELT_OPTION
]

const NON_LABELED_COMMODITY_TYPE_OPTIONS: CommodityTypeOption[] = [
  { id: FLAV, label: FLAV },
  { id: CONC, label: CONC },
  { id: CAN, label: CAN },
  { id: END, label: END },
  { id: WRAP, label: WRAP },
  { id: TRAY, label: TRAY },
  { id: PCK_OTH, label: PCK_OTH },
  { id: MISC, label: MISC },
  { id: DRY_RAW, label: DRY_RAW },
  { id: DEX, label: DEX },
  { id: GFB, label: GFB },
  { id: ELT, label: ELT }
]

interface InstantAppointment {
  appointmentTypeId?: string
  appointmentTypeName?: string
  status: 'created' | 'scheduled' | 'checked-in' | 'checked-out'
  arrivalTime: Date
  checkinId: string
  checkinStatus?: string
  color: string
  commodityType: CommodityType
  confirmationId: string
  createdAt: Date
  updatedAt: Date
  createdById: string
  createdByName: string
  showAppointmentId?: boolean
  equipmentTypeId?: string
  dockName?: string
  dockTime?: Date
  dockType?: DockType
  facilityId: string
  facilityName: string
  timeZone: string
  dockId: string
  hasAttachments: boolean
  id: string
  lessThanTruckload: boolean
  purchaseOrderIdentifiers: string[]
  quantity?: number
  repeating: false
  schedulerId: string
  schedulerName: string
  carrierName?: string
  shipperId: string
  recurringAppointmentBlueprintId?: string
  identifiersJson?: string
  carrierScac?: string
  handlingMethod?: string
  trailerId?: string
  trailerNumber?: string
  trailerStatus?: string
  trailerSlotZone?: string
  trailerSlotName?: string
  trailerDockName?: string
  taskTime?: string | Date
  driverName?: string
  trailerYardJockey?: string
  shouldDisplayForDropOutbound?: boolean
}

interface PurchaseOrderRequest {
  id?: string
  identifier: string
  _destroy?: boolean
}

const TEXT = 'text'
const DOCUMENT = 'document'

type AnswerType = typeof TEXT | typeof DOCUMENT

interface Question {
  id?: string
  prompt?: string
  caption?: string
  answerType?: AnswerType
  audienceType?: DockType[]
  disabled?: boolean | null
  response?: any
  documentName?: string
  equipmentTypeIds?: string[]
  documentUrl?: string
  questionPermissionsAttributes: any[]
  position: number
  minLength?: number
  maxLength?: number
  numericOnly?: boolean
  showOnCreate?: boolean
  postCheckin?: boolean
}
interface Answer {
  id?: string
  question_id?: string
  response?: string
  questionId?: string
  question?: Question
  documentName?: string
  documentUrl?: string
}
interface AppointmentRequest {
  arrivalTime: Date
  facilityId: string
  schedulerId?: string
  purchaseOrdersAttributes: PurchaseOrderRequest[]
  appointmentTypeId?: string
  answersAttributes: Answer[]
  equipmentTypeId: string
  endDate?: Date
  schedule?: Schedule
  recurringDays?: [] | undefined
  carrierId?: string
}

interface Schedule {
  rrules: [
    {
      until?: Date
      interval: number
      rule_type: string
      week_start: number
      validations: {
        day: number[]
      }
    }
  ]
  rtimes: []
  extimes: []
  start_time: Date
}

interface trailer {
  id: string
  number: string
  state: string
  status: string
  dock?: Dock
  slot?: Slot
}

interface Appointment {
  id: string
  arrivalTime: Date
  createdAt: Date
  updatedAt: Date
  facilityId: string
  comment?: string
  schedulerId: string
  schedulerName?: string
  confirmationId?: string
  purchaseOrdersAttributes: PurchaseOrderRequest[]
  appointmentTypeId?: string
  appointmentTypeName?: string
  answersAttributes: Answer[]
  dockType: DockType
  dockName?: string
  dockTime?: Date
  createdByUserName: string
  readOnly: boolean
  checkinId: string
  facilityName?: string
  handlingMethod?: string
  recurringAppointmentBlueprintId?: string
  schedule?: Schedule
  recurringDays?: number[]
  recurringAppointmentBlueprint?: any
  endDate?: any
  equipmentTypeId?: string
  othersToNotify?: string
  carrierId?: string
  carrierName?: string
  dock?: Dock
  trailer?: trailer
  appointment?: Appointment
  relatedAppointment?: Appointment
  appointmentType?:
    | string
    | {
        appointmentTypeId: string
        appointmentTypeName: string
        type: string
      }
  purchaseOrders?: {
    id: string
    identifier: string
  }[]
}

type AppointmentDirectionType = 'inbound' | 'outbound'

export {
  CommodityType,
  COMMODITY_TYPE_OPTIONS,
  NON_LABELED_COMMODITY_TYPE_OPTIONS,
  InstantAppointment,
  AppointmentRequest,
  PurchaseOrderRequest,
  Appointment,
  Question,
  Answer,
  AppointmentDirectionType
}
