import { primitives } from 'components/homepage/custom-theme'
import styled from 'styled-components'

export const DriverConfirmationIcon = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  width: 64px;
  height: 64px;
  padding: 12px;
  gap: 8px;
  border-radius: 24px;
  background-color: ${primitives.accent};
  color: ${primitives.white};
`

export const DriverConfirmationTitle = styled.div`
  color: #082766;
  font-size: 32px;
  font-family: Rubik;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0.15px;
  word-wrap: break-word;
  margin-bottom: 0.5re;
`
export const DriverConfirmationBody = styled.div`
  color: #082766;
  font-size: 16px;
  font-family: Inter;
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
  margin-bottom: 0.5rem;
`

export const DynamicLogo = styled.img`
  max-width: 100%;
  max-height: 50px;
  display: block;
`

export const PostCheckinMessage = styled.div`
  margin: 20px 0 10px 0;
  font-family: ${primitives.primaryFontFamily};
  color: ${primitives.primary};
`

export const DriverGoToSpot = styled.div`
  color: #082766;
  font-size: 16px;
  font-family: Inter;
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
  margin-bottom: 0.5rem;
`
