import { FormControl } from 'baseui/form-control'
import { Answer, Question } from 'components/appointments/types'
import { useCQStateValue } from 'components/contexts/custom-questions.context'
import FileUploader from 'components/shared/file-uploader'
import TemporalFile from 'components/shipper/components/temporalFile'
import { fancyToast } from 'components/utils'
import formatQuestionCaption from 'components/utils/format-question-caption'
import React, { ReactElement, useEffect, useState } from 'react'
import Questions from '../../../../../components/Questions/Questions'
import { DOCUMENT_TYPE } from '../../../../../models/Question'
import { QuestionPermission } from '../../../../../models/QuestionPermission'
import formatQuestionLabel from '../../../../../utils/format-question-label'
import { useTranslation } from 'react-i18next'

interface QuestionAttributes {
  question: Question & { permission?: QuestionPermission }
  answer: Answer
  setAnswer: (answer) => void
  onSaveTemporalFiles?: boolean
  permission?: QuestionPermission
  shouldValidate?: boolean
}

const QuestionAttributes = ({
  question,
  answer,
  setAnswer,
  shouldValidate
}: QuestionAttributes): ReactElement => {
  const { prompt, id, permission } = question
  const [previewData, setPreviewData] = useState<any>(null)
  const {
    temporalFiles,
    actions: { removeTemporalFile }
  } = useCQStateValue()
  const { t } = useTranslation()

  useEffect(() => {
    setPreviewData(temporalFiles.filter(tf => tf.questionId === id)[0]?.files)
  }, [temporalFiles])

  return (
    <FormControl
      label={shouldValidate ? formatQuestionLabel(permission, prompt) : prompt}
      caption={formatQuestionCaption({ question })}>
      {question?.answerType === DOCUMENT_TYPE ? (
        <div>
          {previewData &&
            previewData?.length > 0 &&
            previewData?.map(item => (
              <TemporalFile
                file={item?.file || item}
                onRemoveFile={e => removeTemporalFile(id, e.id)}
              />
            ))}
          <FileUploader
            questionId={question?.id}
            multiple
            disabled={permission && !permission?.canEdit && !!permission.userRole?.shipperId}
            onError={() => fancyToast({ info: 'Error uploading file, try again later' }, 412)}
            options={{
              addFile: true,
              type: 'document',
              customLabel: t('Common.FileUploader.Label.Text')
            }}
          />
        </div>
      ) : (
        <Questions
          answer={answer}
          setAnswer={setAnswer}
          question={question}
          displayError={shouldValidate}
          disabled={permission && !permission?.canEdit && !!permission?.userRole?.shipperId}
          permission={permission}
        />
      )}
    </FormControl>
  )
}

export default QuestionAttributes
