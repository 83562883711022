import { User } from '@sentry/browser'

import { Modal, ModalBody, ModalFooter, ModalHeader } from 'baseui/modal'
import { useCarriersContext } from 'components/contexts/carriers.context'
import TwilioPhoneNumber from 'components/shared/fields/twilio-phone-number'
import StyledSpinner from 'components/shared/styled-spinner'
import { CyberAutocomplete } from 'cyber/CyberAutocomplete'
import CyberInput from 'cyber/input'
import React, { useContext, useEffect, useState } from 'react'
import { connectSearchBox } from 'react-instantsearch-dom'
import CarrierModal from '../Carriers/CarrierModal'
import { useCarrierUserContext } from 'components/contexts/carrier-user.context'
import DeleteButton from 'components/shared/fields/delete-button'
import { SelectSearch } from 'components/components/SelectSearch'
import { useTranslation } from 'react-i18next'
import { StyledLinkStyled } from './CarrierUsers.styled'

import Button from 'components/ui/specific/PrimaryButton'
import FormControl from 'components/ui/generic/FormControl'
import { validateCarrierUser } from 'components/utils/user-validations'
import { CurrentUserContext } from 'components/homepage/current-user-context'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import ErrorButton from 'components/ui/specific/ErrorButton'
import { ConfirmationModal } from 'components/components/ConfirmationModal'

const CarrierUserModal = ({ refine }) => {
  const {
    users: { userToUpdate, loading, isEditModalActive },
    actions: { updateCarrierUser, clearUserToUpdate, revokeAccessCarrierUser, removeCarrierUser }
  } = useCarrierUserContext()

  const { currentUser } = useContext(CurrentUserContext)

  const [user, setUser] = useState<User>(null)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const {
    carriers: { createdCarrier },
    actions: { setModalState }
  } = useCarriersContext()

  const { t } = useTranslation()

  useEffect(() => {
    if (userToUpdate) {
      setUser(userToUpdate)
    }
  }, [userToUpdate])

  useEffect(() => {
    if (createdCarrier && !userToUpdate?.id) {
      setUser({
        ...user,
        carrierId: createdCarrier.id
      })
    }
  }, [createdCarrier])

  const getDisabledUser = () => {
    const shipper_status = userToUpdate?.carrier_users_shippers?.filter(
      shipper => shipper.shipper_id === currentUser.shipperId
    )[0]
    return shipper_status && shipper_status.disabled
  }

  const onClose = () => {
    setUser(null)
    clearUserToUpdate()
    refine()
  }

  const onInactivate = async () => {
    await revokeAccessCarrierUser(userToUpdate, true)
    clearUserToUpdate()
    refine()
  }

  const onActivate = async () => {
    await revokeAccessCarrierUser(userToUpdate, false)
    clearUserToUpdate()
    refine()
  }

  const onRemove = async () => {
    await removeCarrierUser(userToUpdate)
    setOpenConfirmModal(false)
    clearUserToUpdate()
    refine()
  }

  const saveModal = async () => {
    await updateCarrierUser(user, refine)
    clearUserToUpdate()
  }

  const comfirmDelete = () => {
    setOpenConfirmModal(true)
  }

  return (
    <>
      <Modal isOpen={isEditModalActive} unstable_ModalBackdropScroll={true} onClose={onClose}>
        <ModalHeader>{t('Settings.CarrierUsers.CarrierUserModal.Header.Text')}</ModalHeader>

        <ModalBody>
          {loading ? (
            <StyledSpinner />
          ) : (
            <>
              <FormControl
                label={t('Settings.CarrierUsers.CarrierUserModal.Fields.EmailAddress.Label.Text')}>
                <CyberInput
                  autoComplete="off"
                  name="email_address"
                  value={user?.email_address}
                  onChange={evt =>
                    setUser({
                      ...user,
                      email_address: evt.currentTarget.value
                    })
                  }
                />
              </FormControl>
              <FormControl
                label={t('Settings.CarrierUsers.CarrierUserModal.Fields.Name.Label.Text')}>
                <CyberInput
                  name="name"
                  value={user?.name}
                  disabled={loading}
                  onChange={e => {
                    setUser({
                      ...user,
                      name: e.currentTarget.value
                    })
                  }}
                />
              </FormControl>
              <FormControl
                label={t('Settings.CarrierUsers.CarrierUserModal.Fields.PhoneNumber.Label.Text')}>
                <TwilioPhoneNumber disabled={loading} record={user} setRecord={setUser} />
              </FormControl>
              <FormControl
                label={t('Settings.CarrierUsers.CarrierUserModal.Fields.Facility.Label.Text')}>
                <SelectSearch
                  multi
                  record={user}
                  entity="facilities"
                  onValuesChange={ids =>
                    setUser(user => ({
                      ...user,
                      facilities: ids
                    }))
                  }
                  placeholder={t(
                    'Settings.CarrierUsers.CarrierUserModal.Fields.Facility.Placeholder.Text'
                  )}
                />
              </FormControl>
              <div style={{ position: 'relative' }}>
                <FormControl
                  label={t('Settings.CarrierUsers.CarrierUserModal.Fields.Carrier.Label.Text')}>
                  <CyberAutocomplete
                    record={user}
                    setRecord={setUser}
                    indexName="carrier"
                    placeholder={t(
                      'Settings.CarrierUsers.CarrierUserModal.Fields.Carrier.Placeholder.Text'
                    )}
                  />
                </FormControl>
                <StyledLinkStyled onClick={() => setModalState(true)}>
                  {t('Settings.CarrierUsers.CarrierUserModal.Fields.Carrier.CreateCarrier.Text')}
                </StyledLinkStyled>
              </div>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <FlexGrid flexGridColumnCount={1}>
            <FlexGridItem display="flex" justifyContent="space-between">
              <ErrorButton style={{ marginRight: 'auto' }} kind="secondary" onClick={comfirmDelete}>
                {t('Settings.CarrierUsers.CarrierUserModal.Buttons.RemoveUser.Text')}
              </ErrorButton>
              {getDisabledUser() ? (
                <DeleteButton
                  label={t('Settings.CarrierUsers.CarrierUserModal.Buttons.ActivateUser.Text')}
                  onDelete={onActivate}
                  buttonProps={{ disabled: loading }}
                />
              ) : (
                <DeleteButton
                  label={t('Settings.CarrierUsers.CarrierUserModal.Buttons.InactivateUser.Text')}
                  onDelete={onInactivate}
                  buttonProps={{ disabled: loading }}
                />
              )}{' '}
              <Button
                autoFocus
                disabled={validateCarrierUser(user)}
                data-testid={'update-carrier-user-button'}
                isLoading={loading}
                onClick={saveModal}>
                {t('Settings.CarrierUsers.CarrierUserModal.Buttons.Save.Text')}
              </Button>
            </FlexGridItem>
          </FlexGrid>
        </ModalFooter>
      </Modal>
      <CarrierModal />
      <ConfirmationModal
        isOpen={openConfirmModal}
        loading={loading}
        title={t('Settings.CarrierUsers.CarrierUserModal.ConfirmationModal.Title.Text')}
        cancelAction={() => setOpenConfirmModal(false)}
        confirmAction={onRemove}
      />
    </>
  )
}

export default connectSearchBox(CarrierUserModal)
